import React from "react"

import { Imgs } from "../../../@types/common"
import InfoAction from "../../../blocks/infoAction/InfoAction"
import actionImg from "../../../assets/images/developer-kit/action.svg"

const images: Imgs = {
  actionImg,
}

export const DeveloperKitSectionForth = ({ data, ...props }: { className: string; data: any }) => {
  return <InfoAction data={data?.content?.Section4} images={images} {...props} />
}

export default DeveloperKitSectionForth
