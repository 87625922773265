import React from "react"

import DeveloperKitSectionFirst from "./developerKitSections/DeveloperKitSectionFirst"
import DeveloperKitSectionForth from "./developerKitSections/DeveloperKitSectionForth"
import DeveloperKitSectionSecond from "./developerKitSections/DeveloperKitSectionSecond"
// import DeveloperKitSectionThird from "./developerKitSections/DeveloperKitSectionThird"

const sections = [
  { component: (key: number, className: string, data: any) => <DeveloperKitSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <DeveloperKitSectionSecond data={data} className={className} key={key} /> },
  // { component: (key: number, className: string, data: any) => <DeveloperKitSectionThird data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <DeveloperKitSectionForth data={data} className={className} key={key} /> },
]

const DeveloperKitSections = ({ data }: { data: any }) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-developerKit_${i}`, data))}</>
}

DeveloperKitSections.displayName = "DeveloperKitSections"

export default DeveloperKitSections
