import React from "react"

import Section from "../../components/section/Section"
import { Imgs } from "../../pagesStructures/asrTts/asrTtsSections/AsrTtsSectionFirst"

export const InfoAction = ({ className, data, images }: { className: string; data: any; images: Imgs }) => {
  const { heading, paragraph, btnLink, btnText, imgSrc, imgAlt, links } = data

  return (
    <Section className={className}>
      <div className="sova-section-child-container sova-section-child_row">
        <div className="sova-section-child sova-section-child_info-action">
          <div className="sova-section-child info-action_without-margin-bottom">
            <h2 className="sova-section-child sova-h2">{heading}</h2>
            <p className={"sova-section-child sova-section-paragraph sova-section-child-text sova-p-tiny"}>{paragraph}</p>
          </div>
          <div className="sova-section-child">
            <a href={btnLink} target="blank">
              <button className="sova-section-child sova-btn sova-btn_primary sova-btn_big info-action_without-margin-bottom">{btnText}</button>
            </a>
          </div>
        </div>
        <img src={images[imgSrc]} alt={imgAlt} className="sova-section-child sova-section-img sova-section-img_info-img-action" />
      </div>
      {links && (
        <div className="sova-section-child-container_puzzlelib-5 sova-product-link">
          {links.map(({ href, text }: any, key: number) => (
            <a target="blank" href={href} key={key}>
              {text}
            </a>
          ))}
        </div>
      )}
    </Section>
  )
}

export default InfoAction
