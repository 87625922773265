import React from "react"

import { Imgs } from "../../../@types/common"
import InfoImg from "../../../blocks/infoImg/InfoImg"
import infoImg1 from "../../../assets/images/developer-kit/info1.svg"
import infoImg2 from "../../../assets/images/developer-kit/info2.svg"

const images: Imgs = {
  infoImg1: infoImg1,
  infoImg2: infoImg2,
}

export const DeveloperKitSectionSecond = ({ data, ...props }: { className: string; data: any }) => {
  return <InfoImg data={data?.content?.Section2} images={images} {...props} />
}

export default DeveloperKitSectionSecond
