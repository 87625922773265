import React from "react"

import { Imgs } from "../../../@types/common"
import bannerImg1 from "../../../assets/images/developer-kit/banner1.svg"
import bannerImg2 from "../../../assets/images/developer-kit/banner2.svg"
import Banner from "../../../blocks/banner"

const images: Imgs = {
  bannerImg1,
  bannerImg2,
}

export const DeveloperKitSectionFirst = ({ data, ...props }: { className: string; data: any }) => {
  const preparedData = {
    data: {
      content: {
        dataset: {
          bannerBlock: data?.content?.Section1,
        },
      },
    },
  }

  return <Banner /*data={data?.content?.Section1}*/ data={preparedData} imgs={images} {...props} />
}

export default DeveloperKitSectionFirst
