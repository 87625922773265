import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import DeveloperKitSections from "../pagesStructures/developerKit/developerKitSections"

const DeveloperKit = ({ data, pageContext }: { data?: any; pageContext: any }) => {
  const pageContent = data.strapiDeveloperKit.content.find((el: any) => {
    return el.language === pageContext.lang
  })

  return (
    <Layout bgCustomClass="aboutUs">
      <SEO title={pageContent.content.title} description="ASR TTS page" />
      <DeveloperKitSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default DeveloperKit

export const query = graphql`
  query DeveloperKit($url: String) {
    strapiDeveloperKit(url: { eq: $url }) {
      content {
        content {
          title
          Section1 {
            buttons {
              btnLink
              btnText
            }
            images {
              imgAlt
              imgSrc
            }
            text
            title
          }
          Section2 {
            heading
            imgAlt
            imgSrc
            paragraphs
          }
          Section3 {
            cards {
              buttonHref
              buttonText
              imgSrc
              text
              title
            }
            heading
          }
          Section4 {
            btnLink
            btnText
            heading
            imgAlt
            imgSrc
            paragraph
          }
        }
        language
      }
    }
  }
`
